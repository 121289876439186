import { withPrefix } from 'gatsby'
import React, { FC, ReactNode } from 'react'

import { LinkButton } from 'mf-ui-components'

import { sanitiseLink } from '../../../../utils/sanitiseLink'
import { ButtonTag } from './Button.styles'
import { BaseBlockProps } from '../../../../types/BaseBlockProps'

type Props = BaseBlockProps & {
  inverted?: boolean,
  link?: string,
  role?: (typeof LinkButton)['props']['role']
  size?: (typeof LinkButton)['props']['size']
  text?: string,
  children?: ReactNode,
  onClick?: () => void,
  center?: boolean,
  fullWidth?: boolean,
}

export const Button: FC<Props> = ({
  inverted,
  link,
  role,
  size,
  text,
  marginBottom,
  hiddenAt,
  children,
  onClick,
  center,
  fullWidth,
  ...theRest
}) => (
  <ButtonTag
    referrerPolicy="no-referrer-when-downgrade"
    role={role}
    fullWidth={fullWidth}
    centered={center}
    hiddenAt={hiddenAt}
    inverted={inverted}
    href={withPrefix(sanitiseLink(link))}
    size={size}
    onClick={onClick}
    marginBottom={marginBottom}
    {...theRest}
  >
    {text}
    {children}
  </ButtonTag>
)
