import styled, { css } from 'styled-components'

import { LinkButton } from 'mf-ui-components'

import { marginBreakpoints, hideBlockAt } from '../../../../utils/commonAttrs'
import { BaseBlockProps } from '../../../../types/BaseBlockProps'

type Props = BaseBlockProps & {
  centered?: boolean
  fullWidth?: boolean
  size?: typeof LinkButton['props']['size']
  role?: typeof LinkButton['props']['role']
}

export const ButtonTag = styled(LinkButton)<Props>`
  && {
    display: block;
    ${({ marginBottom }) => marginBreakpoints({ marginBottom }, 0)}
    ${({ hiddenAt }) => hideBlockAt({ hiddenAt })}
    margin-left: ${({ centered }) =>
      centered ? 'auto !important' : 'inherit'};
    margin-right: ${({ centered }) =>
      centered ? 'auto !important' : 'inherit'};
    ${({ centered }) => (centered ? 'justify-self: center !important;' : '')}
    ${({ fullWidth }) =>
      fullWidth
        ? css`
            width: 100% !important;
            max-width: 100% !important;
          `
        : css`
            width: fit-content;
          `}
    height: fit-content;
  }
`
